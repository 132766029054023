//@ts-nocheck
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import LoginScreen from '../../blocks/email-account-login/src/LoginScreen.web';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import HelpCenterBlock from '../../blocks/helpcenter/src/HelpCenterBlock.web';
import AboutUs from '../../blocks/contactus/src/AboutUsBlock.web';
import Profilebio from '../../blocks/profilebio/src/index.web';
import OTPConfirm from '../../blocks/otp-input-confirmation/src/OTPConfirmation.web';
import Cart from '../../blocks/shoppingcart/src/ShoppingCart.web';
import ContactusScreen from '../../blocks/contactus/src/ContactUs.web';
import HomeDashboard from '../../blocks/dashboard/src/Dashboard.web';
import ProductDetails from '../../blocks/dashboard/src/ProductDetails.web';
import OrderDetailsBlog from '../../blocks/orderdetailview/src/OrderDetails.web';
import ProtectedRoute from './protectedRoute.web';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions.web';
import Checkout from '../../blocks/shoppingcart/src/Checkout.web';
import Header from '../../components/src/AppHeader/';
import Footer from '../../components/src/AppFooter/';
import OrderSummary from '../../blocks/ordersummary/src/OrderSummary.web';
import OrderPlaced from '../../blocks/ordersummary/src/orderPlaced.web';
import transactionfailed from '../../blocks/ordersummary/src/transactionfailed.web';
import PrivateRoute from './privateRoute.web';
import ProtectRouting from './ProtectRouting.web';
//@ts-ignore
const themes = require('../../blocks/studio-store-ecommerce-theme/src/theme.json');
import './App.css';

// Phone Login Routes (Addtional Features)
import PhoneLogin from '../../blocks/PhoneLogin/src/PhoneLogin.web';

class DebugRouter extends Router {
  constructor(props) {
    super(props);
    console.log('initial history is: ', JSON.stringify(this.history, null, 2));
    this.history.listen((location, action) => {
      console.log(
        `The current URL is ${location.pathname}${location.search}${
          location.hash
        }`
      );
      console.log(
        `The last navigation action was ${action}`,
        JSON.stringify(this.history, null, 2)
      );
    });

    let STORE_BASE_PATH_NAME = '/';

    try {
      STORE_BASE_PATH_NAME =
        new URL(`${process.env.REACT_APP_BASE_URL}`).pathname ?? '';
    } catch {}

    console.log(`STORE_BASE_PATH_NAME::${STORE_BASE_PATH_NAME}`);
  }
}

class WebRoutes extends Component {
  render() {
    const { history } = this.props;
    const Appthem = JSON.parse(localStorage.getItem('appThemData'));
    const StripePubKey = Appthem?.PaymentKeys?.stripe?.stripe_pub_key
      ? Appthem?.PaymentKeys?.stripe?.stripe_pub_key
      : themes?.attributes?.stripe?.stripe_pub_key;
    const stripePromise = loadStripe(StripePubKey);

    let STORE_BASE_PATH_NAME = '/';

    try {
      STORE_BASE_PATH_NAME =
        new URL(`${process.env.REACT_APP_BASE_URL}`).pathname ?? '';
    } catch {}

    return (
      <Elements stripe={stripePromise}>
        <Router history={history} basename={STORE_BASE_PATH_NAME}>
          <div style={{ position: 'relative' }}>
            <Header />
            <div>
              <Switch>
                <PrivateRoute path="/" exact component={LoginScreen} />
                <Route
                  path="/login"
                  exact
                  render={props => <LoginScreen {...props} />}
                />
                <Route
                  path="/forgotpassword"
                  exact
                  component={ForgotPassword}
                />
                <Route path="/phoneLogin" exact component={PhoneLogin} />
                {/* Protect Roues Start */}
                <ProtectedRoute
                  path="/home-page"
                  exact
                  component={HomeDashboard}
                />
                <ProtectRouting
                  path="/otpconfirm"
                  exact
                  component={OTPConfirm}
                />
                <Route path="/help-center" exact component={HelpCenterBlock} />
                <Route
                  path="/help-center/:slug"
                  exact
                  component={HelpCenterBlock}
                />
                <Route path="/aboutus" exact component={AboutUs} />
                <ProtectRouting
                  path="/contact-us"
                  exact
                  component={ContactusScreen}
                />
                <ProtectRouting
                  path="/filterOptions"
                  component={Filteroptions}
                />
                <ProtectRouting
                  path="/profile/myorder/:orderId/:itemId"
                  exact
                  component={OrderDetailsBlog}
                />
                <ProtectRouting path="/cart" exact component={Cart} />
                <ProtectRouting
                  path="/shop/:id"
                  exact
                  component={ProductDetails}
                />
                <ProtectRouting path="/checkout" exact component={Checkout} />
                <ProtectRouting
                  path="/order-summary"
                  exact
                  component={OrderSummary}
                />
                <ProtectRouting
                  path="/order-placed"
                  exact
                  component={OrderPlaced}
                />
                <ProtectRouting
                  path="/transactionfailed"
                  exact
                  component={transactionfailed}
                />
                {/* Protect Route End */}

                {/* Profile Protect Route start */}
                <ProtectedRoute path="/profilebio" component={Profilebio} />
                {/* Profile Protect Route End */}

                {/* Global catch start */}
                <Route component={LoginScreen} />
                {/* Global catch end */}
              </Switch>
              <Footer history={history} basename={STORE_BASE_PATH_NAME} />
            </div>
          </div>
        </Router>
      </Elements>
    );
  }
}

export default withRouter(WebRoutes);
