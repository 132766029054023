import React, { Fragment } from 'react';
import { Button, Form, FormGroup, Input, Container } from 'reactstrap';
import { CgSpinner } from 'react-icons/cg';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { Link, withRouter } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
// @ts-ignore
import content from '../../../components/src/content.js';
//@ts-ignore
import PhoneLoginController, { Props } from "./PhoneLoginController.web";
import '../assets/css/index.scoped.css';
import Loader from '../../../components/src/Loader.web';
import { pwdIcon, phoneIcon } from './assets';
// Customizable Area Start
// Customizable Area End
class ForgotPasswordOTP extends PhoneLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            // Customizable Area Start
            <>
                {this.state.loading && <Loader loading={this.state.loading} />}
                <Container>
                    <div className="yt-forgot-pw-wrap yt-fpp-mb-5 yt-fpp-mt-5">
                        <div className="d-flex flex-wrap yt-frgt-row">
                            <div className="yt-fp-inner-content yt-form-col">
                                <div className="yt-fp-wrap">
                                    {this.state.otpToken == '' ? (
                                        <Link to={{
                                            pathname: "/", state: { "calledFrom": "cart", "redirect": "wishlist" }
                                        }}
                                            className="d-flex align-items-end" >
                                            <RiArrowLeftSLine className="mr-2 yt-fp-back-icn" />
                                            <h3 className="yt-fp-top-back-tag-name">
                                                {this.state.phoneNoError == 'success' ? content.otpVerification : content.continuePhoneLogin}
                                            </h3>
                                        </Link>
                                    ) : ""}

                                    <h2 className="yt-fp-tag-line">
                                        {content.getStarted}
                                    </h2>
                                    <h2 className="yt-fp-tag-line-2">
                                        {this.state.otpToken == '' ? (
                                            <span>
                                                {this.state.phoneNoError == 'success'
                                                    ? content.otpHasBeenSent
                                                    :
                                                    content.phoneLoginTagLine
                                                }
                                            </span>
                                        ) : ""}
                                    </h2>
                                    <div className="yt-fp-form">
                                        {this.state.otpToken == ''
                                            ?
                                            (
                                                <Form onSubmit={this.SendOtpBtn}>
                                                    <FormGroup className={'mb-1 ' + (
                                                        this.state.phoneNoError == 'empty' || this.state.phoneNoError == 'wrong' || this.state.notRegister != ''
                                                            ? 'yt-form-err' : ''
                                                    )}
                                                    >
                                                        <img
                                                            alt=""
                                                            src={phoneIcon}
                                                            className={'yt-fp-icn'}
                                                        />
                                                        <Input
                                                            type="text"
                                                            name="phoneNo"
                                                            id="phoneNo"
                                                            value={this.state.phoneNo}
                                                            ForgotPassFields
                                                            placeholder={'Phone Number'}
                                                            onChange={this.handlePhoneNumber}
                                                            className={
                                                                this.state.phoneNoError == '' || this.state.phoneNoError == 'success'
                                                                    ? 'normal-input'
                                                                    : 'invalid-input'
                                                            }
                                                            invalid={!(this.state.phoneNoError == '' || this.state.phoneNoError == 'success')}
                                                            disabled={this.state.phoneNoError == 'success'}
                                                        />
                                                        <span id="yt-fp-email-error" className="yt-fp-up-message-show" />
                                                        {this.state.phoneNoError == 'empty'
                                                            ?
                                                            (
                                                                <span
                                                                    className="invalid yt-form-err"
                                                                    style={{ position: 'absolute' }}
                                                                >
                                                                    {content.inavlidPhone}
                                                                </span>
                                                            ) : ""
                                                        }
                                                        {this.state.phoneNoError == 'wrong'
                                                            ?
                                                            (
                                                                <span
                                                                    className="invalid yt-form-err"
                                                                    style={{ position: 'absolute' }}
                                                                >
                                                                    {content.phoneMustBeValid}
                                                                </span>
                                                            ) : ""
                                                        }
                                                        {this.state.notRegister
                                                            ?
                                                            (
                                                                <span
                                                                    className="invalid yt-form-err"
                                                                    style={{ position: 'absolute' }}
                                                                >
                                                                    {this.state.notRegister}
                                                                </span>
                                                            )
                                                            : ""
                                                        }
                                                    </FormGroup>

                                                    {this.state.phoneNoError == 'success'
                                                        ?
                                                        (
                                                            <FormGroup
                                                                className={
                                                                    'mt-1 ' +
                                                                    (this.state.otpError == 'empty' ||
                                                                        this.state.otpError == 'invalid' ||
                                                                        this.state.otpError == 'invalidResponse'
                                                                        ? 'yt-form-err'
                                                                        : '')
                                                                }
                                                            >
                                                                <div>
                                                                    <img
                                                                        alt="Password Icon"
                                                                        src={pwdIcon}
                                                                        className={'yt-fp-icn'}
                                                                    />
                                                                    <Input
                                                                        type={this.state.showOTP ? "password" : "text"}
                                                                        name="otp"
                                                                        id="otp"
                                                                        value={this.state.otp}
                                                                        placeholder="Enter OTP"
                                                                        onChange={this.handleOTP}
                                                                    />
                                                                    {this.state.showOTP
                                                                        ?
                                                                        <FaEyeSlash onClick={this.showOTP}
                                                                            className={
                                                                                this.state.otpError != "" ? "yt-pas-eye-icn2" : "yt-pas-eye-icn"
                                                                            } />
                                                                        :
                                                                        <FaEye onClick={this.showOTP}
                                                                            className={
                                                                                this.state.otpError != "" ? "yt-pas-eye-icn2" : "yt-pas-eye-icn"
                                                                            }
                                                                        />
                                                                    }
                                                                </div>
                                                                {this.state.otpError == 'empty' ? (
                                                                    <span
                                                                        className="invalid yt-form-err"
                                                                        style={{ position: 'absolute' }}
                                                                    >
                                                                        {content.pleaseEnterOTP}
                                                                    </span>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {this.state.otpError == 'invalid' ? (
                                                                    <span
                                                                        className="invalid yt-form-err"
                                                                        style={{ position: 'absolute' }}
                                                                    >
                                                                        {content.pleaseEnter5DigitOTP}
                                                                    </span>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {this.state.otpError == 'invalidResponse' ? (
                                                                    <span
                                                                        className="invalid yt-form-err"
                                                                        style={{ position: 'absolute' }}
                                                                    >
                                                                        {content.invalidOTP}
                                                                    </span>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </FormGroup>
                                                        )
                                                        : ""
                                                    }
                                                    <div className="mt-4 pt-1 yt-otp-sd-wrap">
                                                        {this.state.showSpinner
                                                            ?
                                                            (
                                                                <CgSpinner
                                                                    style={{
                                                                        color: 'black',
                                                                        fontSize: 32,
                                                                        width: '100%',
                                                                        margin: 10,
                                                                    }}
                                                                    className="w3-spin"
                                                                />
                                                            )
                                                            :
                                                            (
                                                                <Button type="submit" color="yt-fp-btn mt-1 send-otp-btn" className="smt-btn" block>
                                                                    {this.state.phoneNoError === 'success'
                                                                        ? 'OTP Verify'
                                                                        : 'Submit'
                                                                    }
                                                                </Button>
                                                            )
                                                        }
                                                    </div>
                                                </Form>
                                            ) : ""}
                                    </div>
                                    <Fragment>
                                        {this.state.phoneNoError != 'success' ?
                                            (
                                                <>
                                                    <div className="yt-forpass-bottom-info text-center">
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                //@ts-ignore
                                                                this.props.history.push({ pathname: "/login", state: { activeTab: "2" } });
                                                            }}
                                                            className="yt-fp-email-btn"
                                                        >
                                                            {content.continueViaEmail}
                                                        </span>
                                                    </div>
                                                    <div className="lp-bottom-links">
                                                        <div className="mt-4">
                                                            <p className="yt-login-aggre-tag mb-3 text-center">
                                                                {content.bySigningIn}
                                                            </p>
                                                            <div className="d-flex yt-login-term-privacy-link justify-content-center">
                                                                <Link to="/help-center/Terms%20&%20Conditions">
                                                                    {content.termNcondition}
                                                                </Link>
                                                                <span className="mx-2">&</span>
                                                                <Link to="/help-center/Privacy%20Policy">
                                                                    {content.privacyPolicy}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        {true && (
                                                            <div className="mt-4 mt-4 text-center yt-skip-wrap">
                                                                <span style={{ cursor: 'pointer' }} className="yt-login-skip-btn"
                                                                    onClick={() => {
                                                                        this.guestUserHandler()
                                                                    }}
                                                                >
                                                                    {content.skipAsGuest}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )
                                            :
                                            (
                                                <Fragment>
                                                    {this.state.otpToken == '' && (
                                                        <div className="yt-forpass-bottom-info text-center">
                                                            <div>
                                                                {this.state.timer > 0 ? (
                                                                    <div className="otp-timer">00:{this.state.timer}</div>
                                                                ) : (
                                                                    <Button
                                                                        color="link yt-resent-otp-btn"
                                                                        onClick={this.PhoneNumberConfirm}
                                                                    >
                                                                        {content.resendOTP}
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            )
                                        }
                                    </Fragment>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </>
            // Customizable Area End
        );
    }
}

// @ts-ignore
export default withRouter(ForgotPasswordOTP);
export { ForgotPasswordOTP }
// Customizable Area Start
// Customizable Area End
