export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");


//WEB images
export const emailIcon = require('../assets/emal-icn.png');
export const phoneIcon = require('../assets/telephone.png');
export const pwdIcon = require('../assets/key-icn.png');
export const userIcon = require('../assets/userprofile-icn.png');
export const logoImg = require('../assets/login-image.jpg');
